<template>
    <div id='blogList'>
        <div style="text-align:right; position:relative; ">
            <p style="position:absolute; top:6px; left:15px" v-if="blogs">
                TOTAL : {{ blogs.length }} blog(s)
            </p>
            <!-- <router-link class="btn" :to="{name:'AddBlog'}">Add New Blog</router-link> -->
            <button class="btn green" @click="handleClick">+ Add New Blog</button>
        </div>
        <ListView />
    </div>

    <div class='modal' tabindex="-1" role="dialog" v-if="showModal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form @submit.prevent="handleSubmit">
                    <div class='modal-header'>
                        <h3>Add New</h3>
                    </div>
                    <div class='modal-content' style="margin-bottom:40px">
                        Blog Title : 
                        <input type="text" @change='checkNewBlog' @keydown.enter.prevent="" v-model="title" style='margin-bottom:5px;'>
                        <small v-if="isPending" style='font-size:10px'>checking....</small>
                        <small v-if="isBlogFound"><b style="color:red">title found</b>, please use different title </small>
                    </div>
                    <div class='modal-footer text-right'>
                        <button @click="showModal=false">Close</button>
                        <button class='btn green' v-if="!isPending && !isBlogFound">Create New</button>
                        <button class='btn green' disabled v-if="isPending">Checking...</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class='loadingScreen' v-if="isPending">
        <p>loading...</p>
    </div>
</template>
<script>
import ListView from '@/components/ListView.vue'
import { ref } from '@vue/reactivity'
import useCollection from '@/composables/useCollection'
import getUser from '@/composables/getUser'
import {timestamp} from '@/firebase/config'
import { useRouter } from 'vue-router'
import getCollection from '@/composables/getCollection'

export default {
    components:{ListView},
    setup(props) {
        
        const showModal = ref(false)
        const isPending = ref(false)
        const isBlogFound = ref(false)
        const { getDocByName, addDoc } = useCollection('blogs');
        const blog = ref({})
        const title = ref('');
        const isChecking = ref(false)
        const { user } = getUser()

        const {error, documents:blogs} = getCollection('blogs', null)


        const router = useRouter()

        const handleClick = () =>{
            // alert('y');
            showModal.value = !showModal.value
            // router.push('/blog/AzXBkA8k2jcuVj78GD0m')
            // name.value.focus()
        }

        const handleSubmit = async() => {
            if (title.value.length > 0 && !isBlogFound.value) {
                // console.log('y');
                isPending.value = true
                const res = ref(null);
                res.value =  await addDoc({
                    coverUrl:'',
                    title:title.value,
                    titleIndex:title.value.toLowerCase(),
                    shortDescription:'',
                    blogContent:'',
                    userId:user.value.uid,
                    username:user.value.displayName,
                    hashtags:[],
                    status_publish:false,
                    createdAt:timestamp(),
                    updatedAt:timestamp()
                });
                

                router.push(`/blog/${res.value.id}`)
                isPending.value = false
            }else{
                
            }
        }
        
        const checkNewBlog = async () => {
            // showModal.value = false
            isBlogFound.value = false
            isPending.value = true
            const document = ref(null);
            document.value = await getDocByName(['titleIndex','==',title.value.toLowerCase()])
            console.log(title.value.toLowerCase())
            
            blog.value = document.value.document
            if(document.value.document){
                isBlogFound.value = true
            }else{
                isBlogFound.value = false
            }
            // document.then((res)=>{
                // console.log(blog.value.document)
            // })
            // blog.value = res.document
            isPending.value = false
            // await blog
            // console.log(blog);
            // console.log(blog);
        }


        return {handleClick , showModal, isPending,
            handleSubmit, checkNewBlog, title, isBlogFound,
            blog, blogs}
    }
}
</script>
<style scoped>
    div{
        max-width: 1200px;
        margin:20px auto;
    }

    #blogList{
        max-width: 1000px;
        margin:20px auto;
        margin-bottom: 50px;
    }

    .modal, .loadingScreen{
        max-width: 100vw;
    }

    .modal{
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        margin: 0;
        padding: 0;
    }

    .modal-dialog{
        margin:auto;
        width:400px;
        margin-top: 15vh;
        background: #eee;
        border-radius: 5px;

    }

    .modal-header{
        padding-bottom: 10px;
        margin-bottom: 20px;
        border-bottom:1px solid #ddd;
        
    }

    .loadingScreen{
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background: rgba(0, 0, 0, 0.4);
    }

    .loadingScreen p{
        position: absolute;
        color: white;
        text-align: center;
        width: 100%;
        top: 50%;
    }

</style>