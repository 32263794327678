<template>
    <div style="display:inline-block">
        <button @click="showModal=true">Add Image</button>
    </div>
    <div class="bs-modal" v-if="showModal">
        <form @submit.prevent="handleSubmit" >
            <div class="bs-modal-body">
                <label for="">Add Pictures</label>
                <input type="file" @change="handleChange" >
                <div class="error">{{ error }}</div>
                <div class="text-right">
                    <button v-if="!isPending">Upload</button> <span>{{ uploaded }}</span>
                    <button v-if="isPending" disabled>Uploading</button>
                    <button @click.prevent="showModal=false">Close</button>
                </div>
            </div>
        </form>

    </div>
</template>
<script>
import { ref } from '@vue/reactivity'
import useStorage from '@/composables/useStorage'
export default {
    props:['path'],
    setup(props, context) {
        const file = ref(null)
        const { uploadImage } = useStorage()
        const type = ['image/png', 'image/jpeg', 'image/svg', 'image/webp']
        const error = ref('')
        const isPending = ref(false)
        const inputFile = ref(null)
        const uploaded = ref(null)
        const fileName = ref('')
        const showModal = ref(false)

        const handleChange = (e) => {
            uploaded.value =  null
            file.value = e.target.files[0]
            if(file.value && type.includes(file.value.type) && file.value.size > 0){
                error.value = "";
                fileName.value = file.value.name
            }else{
                error.value = "File not recognized"
                file.value = null
            }
        }

        const handleSubmit = async () => {
            if(file.value){
                isPending.value = true
                await uploadImage(file.value, `${props.path}/${fileName.value}`)
                file.value = null
                uploaded.value = "Image Uploaded"
                isPending.value = false
            }else{
                error.value = "No file to upload"
                uploaded.value =  null
            }
        }

        return { handleChange, error, isPending, handleSubmit, inputFile, uploaded, showModal }
    }
}
</script>
<style lang="">
    
</style>