<template>
  <Navbar />
  <div id="nav">
  </div>
  <router-view/>
</template>

<script>
import { projectAuth } from '@/firebase/config'
import Navbar from '@/components/Navbar.vue'
import { ref } from '@vue/reactivity'

export default{
  components:{Navbar},
  setup() {
    const isLogin = ref(projectAuth.currentUser)

    return {isLogin}
  },
}
</script>


<style>
</style>
