<template>
    
    <div v-if="showPictureForm" style="margin-top:20px">
    </div>

    <div class="form-container" style="padding:20px 40px">
            <router-link :to="{ name:'Blog' }">
                <button class="backlink">
                    &#10229;
                    Back to Blog List
                </button>
            </router-link>


    </div>
    <div v-if="blog">
        <div class='form-container' style="margin-top:20px">

            <h3>EDIT BLOG CONTENT</h3>

            <div class='blog-info'>
                <div style="padding-top:30px">
                    PUBLISHED : 
                    <button :class="{green : status_publish }" @click="changePublish(true)"  class="btn-publish">YES</button>
                    <button :class="{red : !status_publish }" @click="changePublish(false)"  class="btn-publish">NO</button>
                    <div style='margin-top:15px'>
                        TITLE : <input type="text" placeholder="title" v-model="title" style='font-size:16px; margin:5px 0px; margin-top:5px; '><br/>

                    </div>
                    SHORT DESCRIPTION : <textarea placeholder="short description" v-model="shortDescription" maxlength="150" style="font-size:16px;height:150px;margin-top:5px; "></textarea>
                    HASHTAG* :
                    <input type="text" 
                        v-model="hashtagInput" 
                        @keyup.enter="addHashtag"
                        @input="hashtagOnInput"
                        placeholder="Add new hashtag"
                        style='font-size:16px; margin:5px 0px; margin-top:5px; border:none; border-bottom:1px solid #ddd; width:200px; background-color:white; display:inline-block'
                        maxlength="25">
                        <div style='font-size:10px; margin-bottom:12px'>
                            <span v-html="hashtagInfo"></span>
                        </div>
                        <div style='font-size:12px; margin-bottom:12px'>
                            *Please add <b>#travelinfo</b> or <b>#traveltips</b> or <b>#general</b><br/>
                            if you want to make this blog available to all destination
                            
                        </div>
                    <div>
                        <small> <b>Doube Click</b> hashtag to remove it</small> : 
                        <div>
                            <button class='btn-remove-hashtag'  @dblclick='removeHashtag(hashtag)' style="margin-right:5px" v-for="hashtag in hashtags" :key="hashtag">
                                {{hashtag}} 
                            </button>
                        </div>
                    </div>
                    <br>
                    <router-link :to="{ name:'Blog' }">
                        <button class="backlink">
                            &#10229;
                            Back to Blog List
                        </button>
                    </router-link>
                    
                </div>
                <div class='image-container'  style="padding-top:20px; text-align:center">
                    <label for="">Head Picture</label>
                    <img :src="coverUrl" alt="" style="margin-bottom:20px">
                    <br/>
                    <PictureListView :path="path" @changePict="changePict" :option="{type:'select'}" />
                    <AddPictureMultiple :path="path" :option="{tagShow:false}" />
                </div>
            </div>
        </div>
        <div class='form-container'>

            <div style="float:right">
                <PictureListView :path="path" @changePict="changePict" :option="{type:'copy'}" />
            </div>
            <h3>Content</h3>

            <textarea rows='20' placeholder="content" v-model="blogContent" @keydown.tab.prevent=""  style="font-size:16px;background:#eee">
            </textarea>
        </div>
        <div class='form-container' style="text-align:center">
            <router-link :to="{ name:'Blog' }"><button style="width:200px; height:50px" class="backlink">BACK to Blog List</button></router-link>
            <button style="width:200px; height:50px; " v-if="!isPending" onclick="window.location.reload()">REMOVE ALL CHANGES</button>
            <button class="green" style="width:200px; height:50px" v-if="!isPending" @click="handleSubmit" >UPDATE</button>
            <button v-if="isPending" disabled>Saving....</button>
        </div>
    </div>

    <div id='contentView'>
        <h1>{{ title }} (PREVIEW)</h1>
        <p>{{ shortDescription }}</p>
        <hr>
        <div v-html="blogContent"></div>
    </div>

    <div class="notif green" :style="{opacity:(notification ? 1 : 0) }" style='font-size:1.5em' >{{ notification }}</div>


</template>
<script>
import { ref } from '@vue/reactivity'
import useDocument from '@/composables/useDocument'
import getDocument from '@/composables/getDocument'
import AddPicture from '@/components/AddPicture.vue'
import PictureListView from '@/components/PictureListView.vue'
import getUser from '@/composables/getUser'
import {timestamp} from '@/firebase/config'
import { onUpdated } from '@vue/runtime-core'
import AddPictureMultiple from '../components/AddPictureMultiple.vue'

export default {
    props:['id'],
    components:{AddPicture, PictureListView, AddPictureMultiple},
    setup(props) {

        const path = ref('blogs')
        const coverUrl = ref('')
        const title = ref('')
        const shortDescription = ref('')
        const status_publish = ref(null)
        const hashtags = ref([])
        const hashtagInput = ref('')
        const hashtagInfo = ref('')
        const notification = ref(null)
        const hasChange = ref(false)
        const blogContent = ref(`
            <div class='blog-content'>Insert each Paragraph inside this tag</div>
            <div class='blog-content'>Paragraph 1</div>
            <div class='blog-content'>Paragraph 2</div>
            <div class='blog-content'>Paragraph 3</div>
            `)
        const webcontent = ref(null)
        const showPictureForm = ref(false)

        const { updateDoc, deleteDoc } = useDocument('blogs', props.id);
        const { document:blog } = getDocument('blogs',props.id)
        const { user } = getUser()
        const isPending = ref(false)
        const isLoading = ref(true)

        const changePict = (url) =>{
            console.log(url)
            coverUrl.value = url
        }

        const handleSubmit = async() => {
            // console.log(coverUrl.value,
            //     title.value,
            //     shortDescription.value,
            //     blogContent.value,
            //     user.value.uid,
            //     user.value.displayName,
            //     hashtags.value,
            //     status_publish.value)
            isPending.value = true
            await updateDoc({
                coverUrl:coverUrl.value,
                title:title.value,
                shortDescription:shortDescription.value,
                blogContent:blogContent.value,
                userId:user.value.uid,
                username:user.value.displayName,
                hashtags:hashtags.value,
                status_publish:status_publish.value,
                updatedAt:timestamp()
            })

            notification.value = "UPDATED"
            setTimeout(() => {
                notification.value = null
            }, 3000);
            isPending.value = false
        }

        onUpdated(()=>{
            if(isLoading.value){
                isLoading.value = false
                coverUrl.value=blog.value.coverUrl
                title.value=blog.value.title
                shortDescription.value=blog.value.shortDescription
                hashtags.value = blog.value.hashtags
                status_publish.value = blog.value.status_publish
                blogContent.value=blog.value.blogContent
            }
        })

        const changePublish = (status) =>{
            status_publish.value = status
            hasChange.value = true
        }   

        const addHashtag = () =>{
            hashtagInfo.value = '';
            let nH = '';
            if (hashtagInput.value.length > 0){
                let cH = hashtagInput.value.toLowerCase().substring(0,1)
                nH = hashtagInput.value.toLowerCase()
                if (cH !== '#') {
                    nH = '#'+nH
                }
            }

            if (nH.length > 0 && !hashtags.value.includes(hashtags.value)) {
                
                console.log(nH)
                hashtags.value.push(nH);
                // console.log(hashtags.value)
                hashtagInput.value=''
                // hasChange.value = true
            }else if(nH.length == 0){

            }else{
                hashtagInfo.value = "Hashtag <b style='color:red'>already exist</b>"
            }
        }

        const hashtagOnInput = () =>{
            hashtagInfo.value = '';
            if (hashtagInput.value.length > 0 ) {
                hashtagInfo.value = 'press Enter to add'
            }
        }

        const removeHashtag = (hashtag) =>{
            console.log(hashtag)
            hashtags.value = hashtags.value.filter((item)=>{ return item != hashtag; })
            // hasChange.value = true

            // console.log(hashtags.value)
        }

        return {path, coverUrl, title, shortDescription, blogContent, 
        blog, handleSubmit, showPictureForm, isPending,
        changePict, hashtags, hashtagInput, addHashtag, hashtagOnInput,
        hashtagInfo, removeHashtag, status_publish, notification, changePublish
        }

    },
}
</script>
<style scoped>

    .form-container{
        background: #fff;
        padding: 40px;
        max-width: 820px;
        margin: 20px auto 40px auto;
        border-radius: 5px;
    }

    input, textarea{
        background: #eee;
    }

    .blog-info{
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 80px;
    }
    .image-container{
        width: 250px;
        height: 300px;
        overflow: hidden;
    }
    .image-container img{
        max-width: 100%;
        max-height: 200px;
    }
    form{
        max-width: 800px;
    }
    #contentView{
        padding: 40px;
        max-width: 1200px;
        margin: 10px auto;
        background: white;

    }
    .blog-form-container{
        width: 800px;
        margin: auto;
        background: white;
        padding: 30px;
        border-radius: 8px;
        box-shadow: 1px 2px 3px rgba(50,50,50,0.05);
        border: 1px solid  var(--secondary);
    }

    .btn-remove-hashtag{
        position: relative;
        padding-right:23px;
    }

    .btn-remove-hashtag span{
        position:absolute;
        display: inline-block;
        right:10px;

    }

    .btn-remove-hashtag::before{
        content:"+";
        position:absolute;
        right:10px;
        display: block;
        -webkit-transform: rotate(-45deg); 
        -moz-transform: rotate(-45deg); 
        transform:rotate(-45deg);
    }

    .btn-publish{
        border-radius:0px;
        margin:0px;
        padding:6px;
        width:50px;
        font-size:12px;
    }
    
</style>