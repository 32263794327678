<template>
    <div id='destinationList'>
        <div style="text-align:right">
            <router-link style="display:none" class="btn" :to="{name:'AddDestination'}">Add New Destination</router-link>
            <button class="btn" @click="handleClick">Add New Destination</button>
        </div>
        <ListViewDestination />
        <!-- <div>{{destination}}</div> -->
    </div>
    <div class='modal' tabindex="-1" role="dialog" v-if="showModal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form @submit.prevent="handleSubmit">
                    <div class='modal-header'>
                        <h3>Add New</h3>
                    </div>
                    <div class='modal-content' style="margin-bottom:40px">
                        Destination Name : 
                        <input reff='n-barang' type="text" @change='checkNewDestination' @keydown.enter.prevent="" v-model="name" style='margin-bottom:5px; text-transform:uppercase'>
                        <small v-if="isPending" style='font-size:10px'>checking....</small>
                        <small v-if="isDestinationFound">Destination name found, please use different name </small>
                    </div>
                    <div class='modal-footer text-right'>
                        <button @click="showModal=false">Close</button>
                        <button class='btn green' v-if="!isPending && !isDestinationFound">Create New</button>
                        <button class='btn green' disabled v-if="isPending">Checking...</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class='loadingScreen' v-if="isPending">
        <p>loading...</p>
    </div>
</template>
<script>
import ListViewDestination from '@/components/ListViewDestination.vue'
import { ref } from '@vue/reactivity'
import useCollection from '@/composables/useCollection'
import getUser from '@/composables/getUser'
import {timestamp} from '@/firebase/config'
import { useRouter } from 'vue-router'


export default {
    components:{ListViewDestination},
    setup(props) {
        
        const showModal = ref(false)
        const isPending = ref(false)
        const isDestinationFound = ref(false)
        const { getDocByName, addDoc } = useCollection('destination');
        const destination = ref({})
        const name = ref('');
        const isChecking = ref(false)
        const { user } = getUser()

        const router = useRouter()

        const handleClick = () =>{
            // alert('y');
            showModal.value = !showModal.value
            // router.push('/destination/AzXBkA8k2jcuVj78GD0m')
            // name.value.focus()
        }

        const handleSubmit = async() => {
            if (name.value.length > 0 && !isDestinationFound.value) {
                // console.log('y');
                isPending.value = true
                const res = ref(null);
                res.value =  await addDoc({
                    coverUrl:'',
                    hashtags:[],
                    name: name.value.toUpperCase(),
                    status_publish: false,
                    username:user.value.displayName,
                    createdAt:timestamp(),
                    updatedAt:timestamp()
                });
                

                router.push(`/destination/${res.value.id}`)
                isPending.value = false
            }else{
                
            }
        }
        
        const checkNewDestination = async () => {
            // showModal.value = false
            isDestinationFound.value = false
            isPending.value = true
            const document = ref(null);
            document.value = await getDocByName(['name','==',name.value.toUpperCase()])
            
            destination.value = document.value.document
            if(document.value.document){
                isDestinationFound.value = true
            }else{
                isDestinationFound.value = false
            }
            // document.then((res)=>{
                // console.log(destination.value.document)
            // })
            // destination.value = res.document
            isPending.value = false
            // await destination
            // console.log(destination);
            // console.log(destination);
        }


        return {handleClick , showModal, isPending,
            handleSubmit, checkNewDestination, name, isDestinationFound,
            destination}

    }
}
</script>
<style scoped>
    #destinationList{
        max-width: 1000px;
        margin:20px auto;
    }

    form {
        background-color: transparent;
    }

    .modal{
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        margin: 0;
        padding: 0;
    }

    .modal-dialog{
        margin:auto;
        width:400px;
        margin-top: 15vh;
        background: #eee;
        border-radius: 5px;

    }

    .modal-header{
        padding-bottom: 10px;
        margin-bottom: 20px;
        border-bottom:1px solid #ddd;
        
    }

    .loadingScreen{
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background: rgba(0, 0, 0, 0.4);
    }

    .loadingScreen p{
        position: absolute;
        color: white;
        text-align: center;
        width: 100%;
        top: 50%;
    }

    /* .modal-content{max-width: 600px;} */
    
</style>