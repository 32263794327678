<template>
    <div>
        <div id='box-dashboard'>
            <div>
                <h1>Dashboard</h1>
            </div>
            <div class='links'>
                <router-link class='link-button' :to="{name:'AdminInfo'}">Admin Setting</router-link>
                <router-link class='link-button' :to="{name:'WebInfo'}">Important Setting</router-link>
                <router-link class='link-button' :to="{name:'LandingPage'}">Landing</router-link>
                <router-link class='link-button' :to="{name:'Destination'}">Destination</router-link>
                <router-link class='link-button' :to="{name:'Gallery'}">Gallery</router-link>
                <router-link class='link-button' :to="{name:'Blog'}">Blog List</router-link>
                <router-link class='link-button' :to="{name:'AddBlog'}">Create Blog</router-link>
            </div>
        </div>
    </div>
</template>
<script>
import useLogout from '@/composables/useLogout'
import { useRouter } from 'vue-router'
export default {
    setup(props) {
        const {error, logout} = useLogout()
        const router = useRouter();
        const handleLogout = async () => {
            await logout()
            console.log('logout sukses')
            router.push({name:'Home'})
        }

        return { handleLogout }

    }
}
</script>
<style scoped>
#box-dashboard{
    max-width: 400px;
    margin: 0 auto;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 1px 2px 3px rgba(50,50,50,0.05);
    border: 1px solid  var(--secondary);
    background: white;
}

.links{
    display: grid;
    grid-column: auto;
}
.link-button{
    cursor: pointer;
}
.link-button:hover{
    background: var(--secondary);
}
</style>