<template>
  <div class="home">
    <router-view></router-view>
    <div v-if="user">
      <h1>Hi, {{ user.displayName }}</h1>
    </div>
    <div v-else>
      
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import getUser from '@/composables/getUser'
import { useRouter } from 'vue-router'


export default {
    setup(props) {
        const { user } = getUser()
        const router = useRouter()

        if (!user.value) {
          router.push({name:'Login'})
        }
        
        return{user}
    }
}

</script>
<style scoped>
div{
  margin: auto;
  width: 300px;
  margin-top: 10%;

}
</style>