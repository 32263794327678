import { ref } from "vue"
import { projectAuth } from "../firebase/config"
import firebase from 'firebase/app'

const error = ref(null)
const isPending = ref(false)

const login = async (email, password) => {
  error.value = null

  try {
    isPending.value = true
    await projectAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
    const res = await projectAuth.signInWithEmailAndPassword(email, password)
    
    error.value = null
    isPending.value = false
    return res;
  } catch (err) {
    isPending.value = false
    console.log(err.messages)
    // error.value = err.message
    error.value = "Incorrect Login Credentials"
  }

}

const useLogin = () => {
  return {error, login, isPending}
}

export default useLogin