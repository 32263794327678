<template>
    <div class='card' v-for="blog in blogs" :key='blog.id' :class='{published:blog.status_publish}'>
        <div class="card-status">
            {{ blog.status_publish ? 'published' : 'not-published' }}
        </div>
        <div class="card-content">
            <router-link :to="{ name:'EditBlog', params: {id: blog.id} }">
                <div class="single">
                    <div class="thumbnail">
                        <img :src="blog.coverUrl" alt="">
                    </div>
                    <div class="info">
                        <h3>{{ blog.title }} </h3>
                        <small style="background:lightblue; padding:2px 5px; margin-right:5px; border-radius:5px" v-for="(hashtag, index) in blog.hashtags" :key="index"><b>{{hashtag}}</b></small>
                        <p>{{ blog.shortDescription }}</p>
                        <p>created by {{ blog.username }}</p>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>
<script>
import getCollection from '@/composables/getCollection'
export default {
    setup(props) {
        const {error, documents:blogs} = getCollection('blogs', null)
        return {error, blogs}
    }
}
</script>
<style scoped>
    .single{
        display: flex;
        align-items: center;
        padding: 20px;
        border-radius: 10px;
        background: white;
        margin-top: 26px;
        transition: all ease 0.2s;
    }

    .card:hover{
        /* box-shadow: 1px 2px 3px rgba(50, 50, 50, 0.05); */
        box-shadow: 5px 7px 10px rgba(50, 50, 50, 0.2);
        transform: scale(1.02);
        transition: all ease 0.2s;
    }

    .thumbnail{
        max-width: 100px;
        max-height: 100px;
        overflow: hidden;
        border-radius: 10px;
    }

    img{
        max-width: 150%;
        max-height: 150%;
        display: block;
    }

    .info{
        margin: 0 30px;
    }

    .single{
        border-radius: 0px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;

    }

    .card-status{
        height: inherit;
        display: inline;
        position: absolute;
        overflow: hidden;
        writing-mode: vertical-rl;
        text-orientation:mixed;
        letter-spacing: 0px;
        transform: rotate(180deg);
        font-size: 0.8em;
        left: 5px;
        top:10px;
        color: white;
    }


    .card{
        background: lightpink;
        border-radius: 10px;
        position: relative;
    }

    .published{
        background: lightgreen;
    }

    .card, .single{
        min-height: 150px;

    }

    .card-content{
        padding-left:30px;
    }


</style>