import { ref } from "vue"
import { projectFirestore } from "../firebase/config"


const useDocument = (collection, id) => {
    
    const error = ref(null)
    const isPending = ref(false)
    const docRef = projectFirestore.collection(collection).doc(id)

    const deleteDoc = async () => {
        error.value = null

        try {
            const res = await docRef.delete()
            return res
        } catch (err) {
            console.log(err.message);
            error.value = "couldn't delete"
        }
    }

    const updateDoc = async (document) => {
        error.value = null
        
        try {
            const res = await docRef.update(document)
            return res
        } catch (err) {
            console.log(err.message)
            error.value = "couldn't update"
        }
    }

    return {error, deleteDoc, updateDoc}

}

export default useDocument