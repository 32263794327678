<template>
    <div class="modalButton" >
        <button @click="resetForm()">+ New Image(s)</button>
    </div>
    <div class="bs-modal" v-if="showModal">
        <form @submit.prevent="handleSubmit" >
            <div class="bs-modal-body">
                <h4>Add New Images</h4>
                <div class='image-preview'>
                    <div v-for="image in images" :key="image" class='image-container' style='background-color:#ddd' :style="`background-image:url(${image})`">
                    </div>
                </div>
                <span v-for="(fail, index) in imageFailed" :key="index"><span v-html="fail"></span>, </span>
                
                <div v-if="imageFailed.length" class="info-box">Use Only this extention : [ png / jpeg / svg / webp ] <br> Only image that show on top will get uploaded</div>
                <input type="file" @change="handleChange" multiple>
                <input v-if="tagShow" type="text" placeholder="click Enter to add tag" v-model="tagTemp" @keydown.enter.prevent="" @keyup.prevent.enter="handleTag">
                <div class="tagList" @click="deleteTag(tag)" v-for="tag in tagList" :key="tag">{{ tag }} x</div>
                <div class="error">{{ error }}</div>
                <div class="text-right">
                    <button v-if="!isPending">Upload</button> <span>{{ uploaded }}</span>
                    <button v-if="isPending" disabled>Uploading</button>
                    <button @click.prevent="showModal=false">Close</button>
                </div>
            </div>
            <div class="notif" :class="{ 'notif-success' : notifSuccess }">{{ notifSuccess }}</div>
        </form>

    </div>
</template>
<script>
import { ref } from '@vue/reactivity'
import useStorage from '@/composables/useStorage'
import useCollection from '@/composables/useCollection'
import getUser from '@/composables/getUser'
import { timestamp } from '@/firebase/config'


export default {
    props:['path', 'images', 'option'],
    setup(props) {
        const files = ref([])
        const error = ref('')
        const fileTemp = ref(null)
        const isPending = ref(false)
        const uploaded = ref(null)
        const fileNames = ref([])
        const showModal = ref(false)
        const tagTemp = ref('')
        const tagList = ref([])
        const type = ['image/png', 'image/jpeg', 'image/svg', 'image/webp']
        const { filePath, url, uploadImage, res } = useStorage()
        const {addDoc} = useCollection('gallery')
        const {user} =  getUser()
        const notifSuccess = ref(null)
        const tagShow = ref(props.option.tagShow)
        // console.log(tagShow.value)

        const images = ref([])
        const imageFailed = ref([])

        const imageListed = []

        const resetForm = () => {
            // console.log(props.images)
            fileNames.value = []
            imageFailed.value = []
            files.value = []
            images.value = []
            showModal.value=true
            imageListed.value = []
            if (props.images) {
                for (let i = 0; i < props.images.length; i++) {
                    imageListed.push(props.images[i].filePath)    
                }
            }
        }
        

        const handleTag = (e) => {
            e.preventDefault();
            if (e.key === 'Enter') {
                if (!tagList.value.includes(tagTemp.value)) {
                    tagList.value.push(tagTemp.value.toString().toUpperCase())
                }
                tagTemp.value = ''
            }
        }

        const handleChange = (e) => {
            fileNames.value = []
            imageFailed.value = []
            uploaded.value =  null
            fileTemp.value = e.target.files
            console.log(imageListed)
            
            for (let i = 0; i < fileTemp.value.length; i++) {
                if(fileTemp.value[i] && type.includes(fileTemp.value[i].type) && fileTemp.value[i].size > 0 && !imageListed.includes(`${props.path}/${fileTemp.value[i].name}`)){
                    // error.value = "";
                    fileNames.value.push({name:fileTemp.value[i].name,msg:'',color:'black'})
                    files.value.push(fileTemp.value[i])
                    images.value.push(URL.createObjectURL(fileTemp.value[i]))
                }else if(imageListed.includes(`${props.path}/${fileTemp.value[i].name}`)) {
                    fileNames.value.push({name:fileTemp.value[i].name,msg:'File already uploaded before',color:'red'})
                    imageFailed.value.push(`<b>${fileTemp.value[i].name}</b> <span class="error">File exist</span>`)
                }else if(fileTemp.value[i] && type.includes(fileTemp.value[i].type) && fileTemp.value[i].size > 500000){
                    fileNames.value.push({name:fileTemp.value[i].name,msg:'File too large',color:'red'})
                    imageFailed.value.push(`<b>${fileTemp.value[i].name}</b> <span class="error">too large</span>`)
                    // fileTemp.value[i] = null
                } else{
                    fileNames.value.push({name:fileTemp.value[i].name,msg:"File not recognized",color:'red'})
                    imageFailed.value.push(`<b>${fileTemp.value[i].name}</b> <span class="error">not recognized</span>`)
                    // error.value = "File not recognized"
                    // fileTemp.value[i] = null
                }
            }
            console.log(fileNames.value)
        }

        const handleSubmit = async () => {
            fileNames.value = []
            if(files.value.length){
                isPending.value = true
                for (let i = 0; i < files.value.length; i++) {
                    fileNames.value.push({name:files.value[i].name,msg:'ready to upload',color:'black'})
                }

                for (let i = 0; i < files.value.length; i++) {
                    fileNames.value[i].msg = 'uploading...'
                    fileNames.value[i].color = '#ddd'
                    await uploadImage(files.value[i], `${props.path}/${files.value[i].name}`)
                    fileNames.value[i].msg = "uploaded"
                    fileNames.value[i].color = "black"
                    console.log(url, url.value)
                    const res = await addDoc({
                        user_id: user.value.uid,
                        username: user.value.displayName,
                        imageUrl: url.value,
                        filePath: filePath.value,
                        tags:tagList.value,
                        createdAt: timestamp()
                    })


                    // file.value = null
                    // uploaded.value = "Image Uploaded"
                }
                isPending.value = false
                notifSuccess.value = "Data Saved"
                setTimeout(() => {
                    notifSuccess.value = null
                },3000)

            }else{
                error.value = "No file to upload"
                uploaded.value =  null
            }
        }

        const deleteTag = (tag) => {
            tagList.value = tagList.value.filter((item)=>{
                return item !== tag
            })
        }

        return { 
            error, isPending, showModal, tagTemp,
            handleChange, uploaded, fileNames, 
            handleSubmit , handleTag, tagList,
            deleteTag, notifSuccess, images,
            imageFailed, resetForm, tagShow
        }
    }
}
</script>
<style scoped>
    form{
        min-width: 600px;
    }
    .tagList{
        display: inline-block;
        padding: 4px 8px;
        background: #ddd;
        border-radius: 5px;
        margin-right: 8px;
        cursor: pointer;
        font-size: 12px;
    }

    .tagList:hover{
        background: lightcoral;
    }

    .modalButton{
        float: right;
    }

    .image-preview{
        max-height: 350px;
        overflow-y: auto;
        padding: 20px 0px;
        display: flex;
        flex-wrap: wrap;
    }

    .image-container{
        height: 100px;
        width: 100px;
        overflow:hidden;
        margin: 0px 8px 8px 0px;
        background-size: cover;
        background-position: center;
    }

    .image-container img{
        max-width: 100%;
    }
</style>