<template>
    <form @submit.prevent="handleSubmit">
        <h3>Login</h3>
        <input type="text" placeholder="email" v-model="email">
        <div style="position:relative">
            <input :type="passwordType" placeholder="password" v-model="password"><span id="passwordInput" @mousedown="typeText" @mouseup="typePassword" onfocus=""><i :class="`fas fa-${passIcon}`"></i></span>
        </div>
            
        <div v-if="error" class="error">{{ error }}</div>
        <button v-if="!isPending">Log in</button>
        <button v-if="isPending" disabled>loading...</button>
    </form>
</template>
<script>
import { ref } from 'vue'
import useLogin from '@/composables/useLogin'
import { useRouter } from 'vue-router'

export default {
    setup() {

        const email = ref('')
        const password = ref('')
        const router = useRouter()
        const passwordType = ref('password')
        const passIcon = ref('eye-slash')

        const {error, login, isPending} = useLogin()

        const handleSubmit = async () => {
            await login(email.value, password.value)
            if(!error.value){
                router.push({name:'Dashboard'})
            }
        }

        const typeText = async () => {
            passwordType.value = 'text' 
            passIcon.value = 'eye'
        }

        const typePassword = async () => {
            passwordType.value = 'password' 
            passIcon.value = 'eye-slash'
        }

        return { email, password, handleSubmit, error , 
            isPending, passwordType, typeText, typePassword,
            passIcon}

    }
}
</script>
<style scoped>
    #passwordInput{
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        color: #ddd;
    }
</style>