<template>
    <form @submit.prevent="handleSubmit">
        <h3>Sign Up</h3>
        <input type="text" placeholder="display name" v-model="displayName" required>
        <input type="email" placeholder="email" v-model="email" required>
        <input type="password" placeholder="password" v-model="password" required>
        <div class="error">{{ error }}</div>
        <button v-if="!isPending">Sign Up</button>
        <button v-if="isPending" disabled>loading...</button>
    </form>
</template>
<script>
import useSignup from '@/composables/useSignup'
import {useRouter} from 'vue-router'
import { ref } from 'vue'
export default {
    setup() {
        
        const { error, signup, isPending } = useSignup()

        const displayName = ref('')
        const email = ref('')
        const password = ref('')
        const router = useRouter()

        const handleSubmit = async() => {
            await signup(email.value, password.value, displayName.value)
            if(!error.value){
                router.push({name:'Login'})
            }else{
                
            }
        }

        return { displayName, email, password, handleSubmit, error, isPending }
        
    }
    
}
</script>
<style lang="">
    
</style>