<template>
    <div class="content">

        <form @submit.prevent="handleSubmit">
            <h3>Website Admin Info</h3>
            <input type="text" placeholder="Email Admin" disabled v-model="email" >
            <input type="text" placeholder="Username" v-model="displayName" required>
            <input type="password" placeholder="password" v-model="password">
            <p><small>If you don't want to change the password, just leave it blank</small></p>
            <br>
            <div class="success">{{ success }}</div>
            <div class="error">{{ error }}</div>
            <button>Update</button>
        </form>
    </div>
</template>
<script>
import getUser from '@/composables/getUser'
import useUser from '@/composables/useUser'
import { ref } from '@vue/reactivity'   
export default {
    setup(props) {
        const {user} = getUser()
        const email = ref(user.value.email)
        const displayName = ref(user.value.displayName)
        const password = ref('')
        const {updateProfile, updatePassword} = useUser()
        const error = ref(null)
        const success = ref(null)


        const handleSubmit = async() => {
            success.value = null
            error.value = null
            if (user.value.displayName != displayName.value) {
                const res = await updateProfile(displayName.value)
                success.value= 'Username updated'
            }
            
            if (password.value.length > 0 && password.value.length >= 6) {
                await updatePassword(password.value)
                success.value= 'Password updated'
            }else if(password.value.length > 0  && password.value.length < 6){
                error.value = "Password needs 6 characters or more"
            }
            
        }

        return { email, displayName, password, error, success, handleSubmit }

    }
}
</script>
<style scoped>
.content{
    display: flex;
    justify-content: center;
    /* grid-template-columns: repeat(auto-fit, minmax(400px,1fr)); */
    /* grid-template-columns: repeat(auto-fit, minmax(400px,400px)); */
    /* gap: 40px; */
}

form{margin: 20px;}
input{
    display: inline-block;
}
</style>