<template>
    <div class='tagContainer'>
        <button v-for="tag in tags" :key="tag" @click="handleClick(tag)">{{ tag }}</button>
    </div>
</template>
<script>
import { ref } from '@vue/reactivity';

export default {
    props:['images'],
    setup(props, context) {
        const tagList = new Set()
        const tags = ref([]) 
        const selectedTag = ref([])

        if (props.images) {
            props.images.forEach(item => {
                item.tags.forEach(tag => tagList.add(tag));
            });
            
        }

        tags.value = [...tagList]

        const handleClick = (tag) => {
            event.target.classList.toggle('selectedTag')
            if (selectedTag.value.includes(tag)) {
                selectedTag.value = selectedTag.value.filter(item => item !== tag)
            }else{
                selectedTag.value.push(tag)
            }
            context.emit('tag',selectedTag)
        }

        return{tags, handleClick}
    }
    
}
</script>
<style scoped>
    button{
        margin-right : 8px;
    }

    .selectedTag{
        background: tomato;
    }

    .tagContainer{
        border: 1px solid #ddd;
        margin: 25px 0px;
        padding: 20px;
        position: relative;
    }
    
    .tagContainer::before{
        content: "TAG";
        position: absolute;
        top: -10px;
        font-weight: bold;
        background: white;
        padding: 0px 10px;
    }
</style>