<template>
    <div class="content">
        <div class="blog-form-container">
            <AddPictureMultiple :path="path" :images="images" :option="{tagShow:true}" />
            <EditPictureGallery :gallery="singleGallery" v-if="singleGallery" @close="handleClose" />
        </div>
        <h1>Image Gallery</h1>
        <div v-if="images">
            <TagList :images="images" @tag='handleFilter($event)' />
        </div>
        <div v-if="images" class="image-gallery-container">
            <div class='image-gallery'>
                <div class="image-container" v-for="gallery in filteredGallery" @click="handleGallery(gallery)" :key="gallery.name" >
                    <img :src="gallery.imageUrl" alt="">
                    <div class='overlay-img'></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import AddPictureMultiple from '@/components/AddPictureMultiple.vue'
import PictureListView from '@/components/PictureListView.vue'
import EditPictureGallery from '@/components/EditPictureGallery.vue'
import TagList from '@/components/TagList.vue'
import { ref } from '@vue/reactivity'
import getCollection from '@/composables/getCollection'
import { computed } from '@vue/runtime-core'

export default {
    components:{AddPictureMultiple, PictureListView, TagList, EditPictureGallery},
    setup(props) {      
        const isPending = ref(false)
        const path =ref('gallery')
        const tagList = ref([])
        const singleGallery = ref(null)
        
        const {documents:images} = getCollection('gallery')

        const handleFilter = (tags) => {
            tagList.value = tags.value
        }

        const filteredGallery = computed(()=>{
            if (!tagList.value.length) {
                return images.value
            }else{
                return images.value.filter((item) => {
                    var match = 0;
                    tagList.value.forEach(tag => {
                        if (item.tags.includes(tag)) {
                            match++
                        }
                    });
                    return match
                })
            }
        })

        const handleGallery = (gallery) => {
            singleGallery.value = gallery
        }

        const handleClose = () =>{
            singleGallery.value = null
        }
        
        return { 
            images, path, handleFilter, 
            filteredGallery, singleGallery, handleGallery, 
            handleClose
        }

    }
    
}
</script>
<style scoped>
    .content{
        max-width: 1200px;
        margin: 20px auto;
        background: white;
        padding:30px;
        border-radius:5px;
    }
    .image-gallery{
        position: relative;
        height: 318px;
        overflow-y: auto;
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
        margin: 20px;
    }

    .image-gallery-container{
        position: relative;
        border: 1px solid #ddd;
    }

    .image-gallery-container::before{
        content: "IMAGES";
        position: absolute;
        top: -10px;
        left:20px;
        font-weight: bold;
        background: white;
        padding: 0px 10px;
        overflow: visible;
    }

    .image-container{
        position: relative;
        height: 150px;
        width: 150px;
        display: inline-block;
        cursor: pointer;
        margin: 10px;
        overflow: hidden;
        background: #eee;
    }

    .image-gallery img{
        max-height: 100%;
        cursor: pointer;
        position:absolute;
        bottom: 0px;
    }

    .image-container:hover{
        box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.5);
        transform: scale(1.02);
        transition: all ease 0.2s;
    }
    
</style>