<template>
    <div v-if="!setting">loading...</div>
    <div class="content" v-if="setting">
        <form @submit.prevent="handleSubmit">
            <h3>Important Setting</h3>
            <input type="email" placeholder="Email for contact" title="email target if visitor fill a form" v-model="setting.emailContact" required>
            <input type="tel" placeholder="Whatsapp for contact" required title="use your country code first (eg: +62 for Indonesia)" v-model="setting.whatsapp">
            <div class="error">{{ errorWA }}</div>
            <br>
            <h3>Social Media Link</h3>
            <input type="text" class="medsos-input" placeholder="Facebook Page" v-model="setting.facebookUrl">
            <input type="text" class="medsos-input" placeholder="Instagram" v-model="setting.instagramUrl">
            <input type="text" class="medsos-input" placeholder="Twitter" v-model="setting.twitterUrl">
            <input type="text" class="medsos-input" placeholder="Youtube Channel" v-model="setting.youtubeUrl">
            <input type="text" class="medsos-input" placeholder="Tripadvisor Channel" v-model="setting.tripadvisorUrl">
            <div>
            <br>
                <button>Update</button>
            </div>
        </form>
        <div class="notif" :class="{ 'notif-success' : notifSuccess }">{{ notifSuccess }}</div>
    </div>
</template>
<script>
import getDocument from '@/composables/getDocument'
import useDocument from '@/composables/useDocument'
import { ref } from '@vue/reactivity'

export default {
    setup(props) {

        const id = 'contactSetting'
        const errorWA = ref(null)
        const { document:setting } = getDocument('setting',id)
        const { updateDoc } = useDocument('setting',id)
        const notifSuccess = ref(null)

        const handleSubmit = async() => {
            errorWA.value = null

            if(setting.value.whatsapp != '' && setting.value.whatsapp.substring(0,1) != '+'){
                return errorWA.value = "Please start with '+'code country "
            }

            // console.log({
            //     emailContact:setting.value.emailContact,
            //     whatsapp:setting.value.whatsapp,
            //     facebookUrl:setting.value.facebookUrl,
            //     instagramUrl:setting.value.instagramUrl,
            //     twitterUrl:setting.value.twitterUrl,
            //     youtubeUrl:setting.value.youtubeUrl,
            //     tripadvisorUrl:setting.value.tripadvisorUrl
            // })

            const res = await updateDoc({
                emailContact:setting.value.emailContact,
                whatsapp:setting.value.whatsapp,
                facebookUrl:setting.value.facebookUrl,
                instagramUrl:setting.value.instagramUrl,
                twitterUrl:setting.value.twitterUrl,
                youtubeUrl:setting.value.youtubeUrl,
                tripadvisorUrl:setting.value.tripadvisorUrl
            })

            

            notifSuccess.value = "Data Updated"
            setTimeout(() => {
                notifSuccess.value = null
            },3000)
        }

        return { 
            handleSubmit, errorWA , setting, notifSuccess
        }

    }
}
</script>
<style scoped>
.content{
    display: flex;
    justify-content: center;
    /* grid-template-columns: repeat(auto-fit, minmax(400px,1fr)); */
    /* grid-template-columns: repeat(auto-fit, minmax(400px,400px)); */
    /* gap: 40px; */
}

form{
    min-width: 400px;
    max-width: 600px;
    margin: 10px;
}

.medsos-input{
    display: inline-block;
    width: 80%;
    padding: 5px;
    margin: 10px auto;
}
</style>