import { ref } from "vue"
import { projectStorage } from "../firebase/config"


const getStorage = () => {
    const error = ref(null)
    const url = ref(null)
    const filePath = ref(null)

    const getImageList = async (path) => {
        const storageRef = projectStorage.ref(path)
    
        try {
            const res = await storageRef.listAll()
            // const rest2 = await res
            // console.log(rest2)
            error.value = null    
            return res.items
        } catch (err) {
            console.log(err.message)
            error.value = "Couldn't upload image"
        }
    }

    return { error, getImageList }

}

export default getStorage
