<template>
    <div class="blog-form-container">
        <PictureListView :path="path" :option="{type:'select'}" />
        <AddPicture :path="path" />
    </div>

    <div v-if="showPictureForm" style="margin-top:20px">
    </div>
    <div >
        <form @submit.prevent="handleSubmit" style="margin-top:20px">
            <h3>BLOG CONTENT</h3>
            <input type="text" placeholder="title" v-model="title">
            <textarea placeholder="short description" v-model="shortDescription" maxlength="250"></textarea>
            <span class="error">{{ shortDescriptionError }}</span>
            <h3>Content</h3>
            <textarea rows='20' placeholder="content" v-model="blogContent" @keydown.tab.prevent="">
            </textarea>
            <button v-if="!isPending">Save</button>
            <button v-if="isPending" disabled>Saving....</button>
        </form>
    </div>

    <div id='contentView'>
        <h1>{{ title }}</h1>
        <p>{{ shortDescription }}</p>
        <hr>
        <div v-html="blogContent"></div>
    </div>

</template>
<script>
import { ref } from '@vue/reactivity'
import useCollection from '@/composables/useCollection'
import AddPicture from '@/components/AddPicture.vue'
import PictureListView from '@/components/PictureListView.vue'
import getUser from '@/composables/getUser'
import {timestamp} from '@/firebase/config'

export default {
    components:{AddPicture, PictureListView},
    setup(props) {
        const path = ref('blogs')
        const coverUrl = ref('')
        const title = ref('')
        const shortDescription = ref('')
        const shortDescriptionError = ref('')
        const blogContent = ref(`
            <div class='blog-content'>Insert each Paragraph inside this tag</div>
            <div class='blog-content'>Paragraph 1</div>
            <div class='blog-content'>Paragraph 2</div>
            <div class='blog-content'>Paragraph 3</div>
            `)
        const webcontent = ref(null)
        const showPictureForm = ref(false)
        const { addDoc } = useCollection('blogs');
        const { user } = getUser()
        const isPending = ref(false)

        const handleSubmit = async() => {
            shortDescriptionError.value = '';
            if(shortDescription.value.length >= 170){
                isPending.value = true
                await addDoc({
                    title:title.value,
                    shortDescription:shortDescription.value,
                    blogContent:blogContent.value,
                    userId:user.value.uid,
                    username:user.value.displayName,
                    createdAt:timestamp()
                })
                isPending.value = false
            }else{
                shortDescriptionError.value = "Short description too short, use 170 words minimal"
            }
        }

        return {
            path, coverUrl, title, shortDescription, 
            blogContent, webcontent, handleSubmit, 
            showPictureForm, isPending, shortDescriptionError
        }

    },
}
</script>
<style scoped>
    form{
        max-width: 800px;
    }
    #contentView{
        padding: 40px;
        max-width: 1200px;
        margin: 10px auto;
        background: white;

    }
    .blog-form-container{
        width: 800px;
        margin: auto;
        background: white;
        padding: 30px;
        border-radius: 8px;
        box-shadow: 1px 2px 3px rgba(50,50,50,0.05);
        border: 1px solid  var(--secondary);
    }
    
</style>