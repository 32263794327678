import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/auth/Login.vue'
import Signup from '../views/auth/Signup.vue'
import Dashboard from '../views/Dashboard.vue'
import WebInfo from '../views/WebInfo.vue'
import AdminInfo from '../views/AdminInfo.vue'
import Blog from '../views/Blog.vue'
import AddBlog from '../views/AddBlog.vue'
import EditBlog from '../views/EditBlog.vue'
import LandingPage from '../views/LandingPage.vue'
import Gallery from '../views/Gallery.vue'
import Destination from '../views/Destination.vue'
import EditDestination from '../views/EditDestination.vue'
import AddDestination from '../views/AddDestination.vue'

import { projectAuth } from '@/firebase/config'

const requireAuth = (to, from, next) => {
  if(projectAuth.currentUser){
    next()
  }else{
    router.push({name:'Login'})
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    // beforeEnter: requireAuth
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: requireAuth
  },
  {
    path: '/admin-info',
    name: 'AdminInfo',
    component: AdminInfo,
    beforeEnter: requireAuth
  },
  {
    path: '/webinfo',
    name: 'WebInfo',
    component: WebInfo,
    beforeEnter: requireAuth
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog,
    beforeEnter: requireAuth
  },
  {
    path: '/blog-create',
    name: 'AddBlog',
    component: AddBlog,
    beforeEnter: requireAuth
  },
  {
    path: '/blog/:id',
    name: 'EditBlog',
    component: EditBlog,
    beforeEnter: requireAuth,
    props:true
  },
  {
    path: '/landing',
    name: 'LandingPage',
    component: LandingPage,
    beforeEnter: requireAuth
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: Gallery,
    beforeEnter: requireAuth
  },
  {
    path: '/destination',
    name: 'Destination',
    component: Destination,
    beforeEnter: requireAuth
  },
  {
    path: '/destination/:id',
    name: 'EditDestination',
    component: EditDestination,
    beforeEnter: requireAuth,
    props:true
  },
  {
    path: '/add-destination/',
    name: 'AddDestination',
    component: AddDestination,
    beforeEnter: requireAuth
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
