import { ref } from 'vue';
import { projectAuth } from '../firebase/config';

// refs & signup outside of exported function
// they don't need to be re-created every time we invoke useSignup
const error = ref(null);

const updateProfile = async (displayName) => {
  error.value = null

  try {
    await projectAuth.currentUser.updateProfile({ displayName })
  }
  catch(err) {
    console.log(err.message)
    error.value = err.message;
  }
}

const updatePassword = async (password) => {
    error.value = null
  
    try {
      const res = await projectAuth.currentUser.updatePassword(password)
    }
    catch(err) {
      console.log(err.message)
      error.value = err.message;
    }
  }

const useUser = () => {
  return { error, updateProfile, updatePassword }
}

export default useUser