import { ref } from "vue"
import { projectStorage } from "../firebase/config"


const useStorage = () => {
    const error = ref(null)
    const url = ref(null)
    const filePath = ref(null)
    
    const uploadImage = async (file, path) => {
        filePath.value = path
        const storageRef = projectStorage.ref(filePath.value)
    
        try {
            const res = await storageRef.put(file)
            url.value = await res.ref.getDownloadURL()
        } catch (err) {
            console.log(err.message)
            error.value = "Couldn't upload image"
        }
    }

    const deleteImage = async (path) => {
        const storageRef = projectStorage.ref(path)
        try {
            const res = await storageRef.delete()
        } catch (err) {
            console.log(err.message)
            error.value = "Couldn't delete image"
        }
    }

    return { error, filePath, url, uploadImage, deleteImage}

}

export default useStorage
