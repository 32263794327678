<template>
    <div class="navbar">
        <nav>
            <h1><router-link :to="{name:'Home'}">Goong2000</router-link></h1>
            <div class="links">
                <div v-if="user">
                    Hi, {{ user.displayName }}
                    <router-link :to="{ name:'Dashboard' }">Dashboard</router-link>
                    <button @click="handleLogout">Logout</button>
                </div>
                <div v-else>
                    <router-link :to="{name:'Login'}" class="btn">Log in</router-link>
                </div>
            </div>
        </nav>
    </div>
</template>
<script>
import useLogout from '@/composables/useLogout'
import getUser from '@/composables/getUser'
import { useRouter } from 'vue-router'

export default {
    setup() {
        const {error, logout} = useLogout()
        const {user} = getUser()
        const router = useRouter()
    
        const handleLogout = async () => {
            await logout()
            console.log('logout sukses')
            router.push({name:'Login'})
        }

        return { error, handleLogout, user }
    }
}
</script>
<style scoped>
    .navbar{
        padding: 16px 10px;
        margin-bottom: 60px;
        background: white;
    }

    nav{
        display: flex;
        align-items: center;
        max-width: 1200px;
        margin: 0 auto;
    }

    nav h1{
        margin-left: 20px;
    }

    nav .links{
        margin-left: auto;
    }

    nav .links a, button{
        margin-left: 16px;
        font-size: 14px;
    } 

    nav img{
        max-height: 60px;
    }
</style>