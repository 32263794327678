<template>
    <div v-if="showPictureForm" style="margin-top:20px">
    </div>
    <div v-if="destination">
        <div id='form-container' >
            <h3>EDIT DESTINATION CONTENT</h3>
            <div class='blog-info'>
                <div class='image-container' style="padding-top:20px; text-align:center">
                    <img :src="coverUrl" alt="" style="margin-bottom:20px">

                    <PictureListView :path="path" :getBlogPict="getBlogPict" @changePict="changePict"  :option="{type:'select'}" />
                    <AddPicture :path="path"  />
                        <router-link :to="{ name:'Destination' }">
                            <button class="backlink">
                                &#10229;
                                Back to Destination
                            </button>
                        </router-link>
                </div>
                <div class='form-container' style="padding-top:15px">
                    PUBLISHED : 
                    <button :class="{green : status_publish }" @click="changePublish(true)" @click.prevent="" class="btn-publish">YES</button>
                    <button :class="{red : !status_publish }" @click="changePublish(false)" @click.prevent class="btn-publish">NO</button>
                    <br/> 
                    NAME : <input type="text" placeholder="name" v-model="name" style="text-transform:uppercase">
                    ADD HASHTAG : <input type="text" 
                        v-model="hashtagInput" 
                        @keyup.enter="addHashtag"
                        @input="hashtagOnInput"
                        style='text-transform:lowercase; margin-bottom:2px'>
                    <div style='font-size:10px; margin-bottom:12px'>
                        <span v-html="hashtagInfo"></span>
                    </div>
                    
                    <div>
                        
                        <small> <b>Doube Click</b> hashtag to remove it</small> : 
                        <div>
                            <button class='btn-remove-hashtag'  @dblclick='removeHashtag(hashtag)' style="margin-right:5px" v-for="hashtag in hashtags" :key="hashtag">
                                {{hashtag}} 
                            </button>
                        </div>
                    </div>

                    <div style='margin-top:70px;'>
                        <button @click='handleSubmit' :disabled='!hasChange' :class="{green:hasChange}" style="width:100%;" >SAVE UPDATE</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class='loadingScreen' v-if="isPending">
        <p>updating...</p>
    </div>
    <div class="notif" :class="notificationType" :style="{opacity:(notification ? 1 : 0) }" style='font-size:1.5em' >{{ notification }}</div>

</template>
<script>
import { ref } from '@vue/reactivity'
import useDocument from '@/composables/useDocument'
import getDocument from '@/composables/getDocument'
import AddPicture from '@/components/AddPicture.vue'
import PictureListView from '@/components/PictureListView.vue'
import getUser from '@/composables/getUser'
import {timestamp} from '@/firebase/config'
import { onUpdated } from '@vue/runtime-core'

export default {
    props:['id'],
    components:{AddPicture, PictureListView},
    setup(props) {

        const path = ref('destination')
        const coverUrl = ref('')
        const name = ref('')
        const status_publish = ref(false)
        const shortDescription = ref('')
        const hashtags = ref([])
        const hashtagInput = ref('')
        const hashtagInfo = ref('')
        const showPictureForm = ref(false)
        const notification = ref(null)
        const notificationType = ref(null)

        const { updateDoc, deleteDoc } = useDocument('destination', props.id);
        const { document:destination } = getDocument('destination',props.id)
        const { user } = getUser()
        const isPending = ref(false)
        const hasChange = ref(false)
        const isLoading = ref(true)
        
        const changePublish = (status) =>{
            status_publish.value = status
            hasChange.value = true
        }    

        const addHashtag = () =>{
            hashtagInfo.value = '';
            let nH = '';
            if (hashtagInput.value.length > 0){
                let cH = hashtagInput.value.toLowerCase().substring(0,1)
                nH = hashtagInput.value.toLowerCase()
                if (cH !== '#') {
                    nH = '#'+nH
                }
            }

            if (nH.length > 0 && !hashtags.value.includes(nH)) {
                
                console.log(nH)
                hashtags.value.push(nH);
                // console.log(hashtags.value)
                hashtagInput.value=''
                hasChange.value = true
            }else if(nH.length == 0){

            }else{
                hashtagInfo.value = "Hashtag <b style='color:red'>already exist</b>"
            }
        }

        const removeHashtag = (hashtag) =>{
            console.log(hashtag)
            hashtags.value = hashtags.value.filter((item)=>{ return item != hashtag; })
            hasChange.value = true

            // console.log(hashtags.value)
        }

        const hashtagOnInput = () =>{
            hashtagInfo.value = '';
            if (hashtagInput.value.length > 0 ) {
                hashtagInfo.value = 'press Enter to add'
            }
        }

        const changePict = (url) =>{
            coverUrl.value = url
            hasChange.value = true

        }

        const handleSubmit = async() => {
            if (hasChange.value) {
                isPending.value = true
                await updateDoc({
                    coverUrl:coverUrl.value,
                    name:name.value,
                    status_publish: status_publish.value,
                    hashtags: hashtags.value,
                    username:user.value.displayName,
                    updatedAt:timestamp()
                    
                })
                notification.value = 'updated'
                notificationType.value = 'green'
                setTimeout(() => {
                    notification.value = null
                    notificationType.value = null
                }, 3000);
                isPending.value = false
                hasChange.value = false
            }
        }

        onUpdated(()=>{
            if(isLoading.value){
                isLoading.value = false
                coverUrl.value = destination.value.coverUrl
                name.value = destination.value.name
                status_publish.value =  destination.value.status_publish
                hashtags.value =  destination.value.hashtags
            }
        })

        return {path, coverUrl, name, shortDescription, 
        destination, handleSubmit, showPictureForm, isPending,
        hashtags, hashtagInput, hashtagInfo, addHashtag, 
        hashtagOnInput, removeHashtag, hasChange, notificationType,
        notification, changePict, status_publish, changePublish}
// @submit.prevent="handleSubmit" style="margin-top:20px"
    },
}
</script>
<style scoped>

    .backlink{
        position: absolute;
        bottom: 0px;
        left: 0px;
        font-size: 14px;
        padding: 8px 16px;
        background: #ddd;
    }

    .backlink:hover{
        color:black;
    }

    #form-container{
        max-width:800px;
        min-width: 400px;
        margin: 0 auto;
        padding: 30px;
        border-radius: 8px;
        box-shadow: 1px 2px 3px rgba(50,50,50,0.05);
        border: 1px solid  var(--secondary);
        background: white;
    }

    .btn-publish{
        border-radius:0px;
        margin:0px;
        padding:6px;
        width:50px;
        font-size:12px;
    }

    .btn-remove-hashtag{
        position: relative;
        padding-right:23px;
    }

    .btn-remove-hashtag span{
        position:absolute;
        display: inline-block;
        right:10px;

    }

    .btn-remove-hashtag::before{
        content:"+";
        position:absolute;
        right:10px;
        display: block;
        -webkit-transform: rotate(-45deg); 
        -moz-transform: rotate(-45deg); 
        transform:rotate(-45deg);
    }

    .blog-info{
        display: grid;
        grid-template-columns: 1.5fr 2.5fr;
        gap: 40px;
    }
    .image-container{
        width: 100%;
        overflow: hidden;
        position: relative;
    }

    .form-container input{
        border: 2px solid #ccc;
        border-radius: 5px;
        display: inline-block;
        margin: 10px 0 ;
    }
    

    .form-container{
        padding:20px 10px;
    }
    .image-container img{
        max-width: 100%;
    }
    form{
        max-width: 800px;
    }
    #contentView{
        padding: 40px;
        max-width: 1200px;
        margin: 10px auto;
        background: white;

    }
    .blog-form-container{
        width: 800px;
        margin: auto;
        background: white;
        padding: 30px;
        border-radius: 8px;
        box-shadow: 1px 2px 3px rgba(50,50,50,0.05);
        border: 1px solid  var(--secondary);
    }

    .loadingScreen{
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background: rgba(0, 0, 0, 0.4);
    }

    .loadingScreen p{
        position: absolute;
        color: white;
        text-align: center;
        width: 100%;
        top: 50%;
    }
    
</style>