<template>
    <h2 class="text-center" >Destination</h2>
    <div class='card' v-for="place in destination" :key='place.id' >
        <router-link :to="{ name:'EditDestination', params: {id: place.id} }">
            <div class="single" :class="(place.status_publish ? 'published' : 'unpublished' )">
                <div class="thumbnail">
                    <img :src="place.coverUrl" alt="">
                </div>
                <div class="info">
                    <h3>{{ place.name }}</h3>
                    <span v-if="place.status_publish" style="font-size:0.9em">
                        <span style="color:blue">published</span> 
                    </span>
                    <span v-else style="font-size:0.9em">
                        <span style="color:red">unpublished</span> 
                    </span>
                </div>
            </div>
        </router-link>
    </div>
</template>
<script>
import getCollection from '@/composables/getCollection'
export default {
    setup(props) {
        const {error, documents:destination} = getCollection('destination', null)
        return {error, destination}
    }
}
</script>
<style scoped>

    .card{
        width: 480px;
        display: inline-block;
        padding-top:10px;
    }

    .card:nth-child(odd){
        padding-right:40px;
    }

    .single{
        display: flex;
        align-items: center;
        padding: 20px;
        border-radius: 5px;
        background: white;
        margin-top: 26px;
        transition: all ease 0.2s;
    }

    .single:hover{
        box-shadow: 5px 7px 10px rgba(50, 50, 50, 0.5);
        transform: scale(1.02);
        transition: all ease 0.2s;
    }

    .thumbnail{
        width: 100px;
        height: 100px;
        overflow: hidden;
        border-radius: 5px;
    }

    img{
        max-width: 150%;
        max-height: 150%;
        display: block;
    }

    .info{
        margin: 0 30px;
    }


</style>