<template>
    <div class="blog-form-container">
        <PictureListView :path="path"  :option="{type:'select'}" />
        <AddPicture :path="path"  />
    </div>

    <div v-if="showPictureForm" style="margin-top:20px">
    </div>
    <div>
        <form @submit.prevent="handleSubmit" style="margin-top:20px">
            <h3>ADD NEW DESTINATION</h3>
            <div class='blog-info'>
                <div class='image-container'>
                    <label for="">Main Picture</label>
                    <img :src="coverUrl" alt="">
                </div>
                <div>
                    <input type="text" placeholder="name" v-model="name">
                    <input type="text" placeholder="hashtag" v-model="hashtag">
                </div>

            </div>

        </form>
    </div>

</template>
<script>
import { ref } from '@vue/reactivity'
import AddPicture from '@/components/AddPicture.vue'
import PictureListView from '@/components/PictureListView.vue'
import getUser from '@/composables/getUser'
import {timestamp} from '@/firebase/config'

export default {
    props:['id'],
    components:{AddPicture, PictureListView},
    setup(props) {

        const path = ref('destination')
        const coverUrl = ref('')
        const name = ref('')
        const hashtag = ref('')
        const showPictureForm = ref(false)

        const { user } = getUser()
        const isPending = ref(false)
        const isLoading = ref(true)

        const handleSubmit = async() => {
            isPending.value = true
            await updateDoc({
                coverUrl:coverUrl.value,
                name:name.value,
                userId:user.value.uid,
                hashtag:user.value.displayName,
                createdAt:timestamp()
            })
            isPending.value = false
        }

        return {path, coverUrl, name, handleSubmit, showPictureForm, isPending, hashtag}

    },
}
</script>
<style scoped>
    .blog-info{
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 80px;
    }
    .image-container{
        width: 250px;
        height: 250px;
        overflow: hidden;
    }
    .image-container img{
        max-width: 100%;
    }
    form{
        max-width: 800px;
    }
    #contentView{
        padding: 40px;
        max-width: 1200px;
        margin: 10px auto;
        background: white;

    }
    .blog-form-container{
        width: 800px;
        margin: auto;
        background: white;
        padding: 30px;
        border-radius: 8px;
        box-shadow: 1px 2px 3px rgba(50,50,50,0.05);
        border: 1px solid  var(--secondary);
    }
    
</style>