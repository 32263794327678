import { ref } from "vue"
import { projectFirestore } from "../firebase/config"


const useCollection = (collection) => {
    
    const error = ref(null) 
    const isPending = ref(false)

    const addDoc = async (doc) => {
        error.value = null

        try {
            isPending.value = true
            const res = await projectFirestore.collection(collection).add(doc)
            isPending.value = false
            return res
        } catch (err) {
            isPending.value = false
            console.log(err.message);
            error.value = "couldn't send the message right now"
        }
    }


    const getDocByName = async (condition) => {
        const document = ref(null)

        await projectFirestore.collection(collection)
            .where(...condition)
            .get()
            .then((querySnapshot) => {
             querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.data())
                document.value = {...doc.data(),id:doc.id}
            });
            console.log(...condition)
            console.log(document.value)
        }).catch((error)=>{
            error.value = "Couldn't get document "
        });
        return {document}
        
    }

    return {error, addDoc, getDocByName}

}

export default useCollection