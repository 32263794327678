<template>
    <div class="bs-modal" v-if="showModal">
        <form @submit.prevent="handleSubmit" >
                <h4>Edit Images Data</h4>
            <div class="bs-modal-body">
                <div class="text-center">
                    <img :src="gallery.imageUrl" alt="">
                </div>
                <div>
                    <input type="text" placeholder="click Enter to add tag" v-model="tagTemp" @keydown.enter.prevent="" @keyup.prevent.enter="handleTag">
                    <div class="tagList" @click="deleteTag(tag)" v-for="tag in tagList" :key="tag">{{ tag }} x</div>
                </div>
                <div class=" modal-footer">
                    <button class='button-danger' v-if="!deleteConfirm" @submit.prevent='' @click="deleteConfirm=true">Delete Data</button>
                    <button class='button-danger confirm-danger' title='click if sure' @click="handleDelete" v-if="deleteConfirm">Are you sure ?</button>
                    <button v-if="!isPending">Save</button>
                    <button v-if="isPending" disabled>Updating</button>
                    <button @click.prevent="handleClose">Close</button>
                </div>
                <br/>
            </div>
            <div class="notif" :class="{ 'notif-success' : notifSuccess }">{{ notifSuccess }}</div>
        </form>

    </div>
</template>
<script>
import { ref } from '@vue/reactivity'
import useDocument from '@/composables/useDocument'

export default {
    props:['gallery'],
    setup(props, context) {
        const showModal = ref(true)
        const isPending = ref(false)
        const notifSuccess = ref(null)
        const deleteConfirm = ref(false)

        const tagTemp = ref('')
        const tagList = ref(props.gallery.tags)
        const gallery =  ref(props.gallery)
        
        const {updateDoc,deleteDoc} = useDocument('gallery',props.gallery.id)

        const handleTag = (e) => {
            e.preventDefault();
            if (e.key === 'Enter') {
                if (!tagList.value.includes(tagTemp.value)) {
                    tagList.value.push(tagTemp.value.toString().toUpperCase())
                }
                tagTemp.value = ''
            }
        }

        const deleteTag = (tag) => {
            tagList.value = tagList.value.filter((item)=>{
                return item !== tag
            })
        }
        

        const handleClose = () => {
            context.emit('close')
            deleteConfirm.value=false
        }

        const handleSubmit = async() => {
            await updateDoc({
                tags:tagList.value,
            })

            notifSuccess.value = "Data Updated"
            setTimeout(() => {
                notifSuccess.value = null
            },3000)

        }

        const handleDelete = async() => {
            deleteConfirm.value = false
            await deleteDoc()
            notifSuccess.value = "Data Deleted"
            setTimeout(() => {
                notifSuccess.value = null
            },3000)   
        }

        return { 
            showModal, isPending, handleTag, deleteTag, 
            tagList, tagTemp, gallery, handleClose,
            handleSubmit, handleDelete, notifSuccess,
            deleteConfirm

        }
    }
}
</script>
<style scoped>
    form{
        width: 600px;
        position: relative;;
    }

    .tagList{
        display: inline-block;
        padding: 4px 8px;
        background: #ddd;
        border-radius: 5px;
        margin-right: 8px;
        cursor: pointer;
        font-size: 12px;
        margin-top:5px;
    }

    .tagList:hover{
        background: lightcoral;
    }

    img{
        max-width: 100%;
        max-height: 200px;
    }

    .modal-footer{
        height: 100px;
        position: absolute;
        /* padding-top:20px ; */
        right: 10px;
    }
</style>