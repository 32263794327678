<template>
    <div style="display:inline-block">
        <button @click="handleClick">Image List</button>
    </div>
    <div class='bs-modal' v-if="showModal">
        <div class="bs-modal-content">
            <div class='bs-modal-body'>
                <h3>Picture List</h3>
                <p v-if="isSelect">Click one of these images to use</p>
                <p v-if="isCopy"><ul>
                    <li>Click one of these images to get the source link</li>
                    <li>Paste it inside the blog content editor below</li>
                </ul></p>
                <div class='image-container'>
                    <div class="image-single" v-for="(item, index) in items" :key="index">
                        <div v-if="isSelect" @click="selectPict(item.url)" class="image-item"
                            :style="`
                                background-image:url(${item.url}); 
                                background-position:center center; 
                                background-size:cover`">
                        </div>

                        <div v-if="isCopy" @click="urlPict(index)"  class="image-item" 
                            :style="`
                                background-image:url(${item.url}); 
                                background-position:center center; 
                                background-size:cover`">
                            <input :id="'pict-'+index" :value="item.url" style='opacity:0'>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class='bs-modal-footer'>
                <button @click="refreshImage" v-if="!isPending">Refresh</button>
                <button v-if="isPending" disabled>Loading...</button>
                <button @click="showModal=false">Close</button>
            </div>
        </div>
    </div>
    <div class="notif green" :style="{opacity:(notification ? 1 : 0) }" style='font-size:1.5em' >{{ notification }}</div>
</template>
<script>
import getStorage from '@/composables/getStorage'
import { ref } from '@vue/reactivity'
export default {
    props:['path', 'getBlogPict','option'],
    emits:['changePict'],
    setup(props, context) {
        const {getImageList} = getStorage()
        const items = ref([])
        const names = ref([])
        const showModal = ref(false)
        const isPending = ref(false)
        const isGetBlogPict = ref(props.getBlogPict)
        const isSelect = ref(false)
        const isCopy = ref(false)
        const notification = ref(null)
        
        if (props.option.type == 'select') {
            isSelect.value = true
        }

        if (props.option.type == 'copy') {
            isCopy.value = true
        }


        const selectPict = (url) =>{
            console.log(url)
            showModal.value = false
            context.emit("changePict",url)

        }

    
        const urlPict = (index) =>{
            const copyText = document.querySelector(`#pict-${index}`)
            copyText.setAttribute('type', 'text')
            copyText.select()
            document.execCommand('copy')
            showModal.value = false

            notification.value = "Image Link Copied"
            setTimeout(() => {
                notification.value = null
            }, 3000);
        }

        const handleClick = async()=>{
            if(!items.value.length){
                isPending.value = true
                const res = await getImageList(`${props.path}/`)
                res.forEach( async(item)=>{
                    const res2 = await item.getDownloadURL()
                    items.value.push({
                        url : res2,
                        name : item.name
                    })
                })
                isPending.value = false
            }
            showModal.value = true
        }

        const refreshImage = () =>{
            items.value = []
            handleClick()
        }
        
        return {handleClick, items, showModal, urlPict, refreshImage, 
            isPending, selectPict, isGetBlogPict, isSelect, isCopy,
            notification}
    }
}
</script>
<style scoped>
.bs-modal-content{
    max-width: 1200px;
}

.bs-modal-footer{
    margin-bottom: 20px;
}

ul{
    padding-left: 20px;
}

.image-container{
    display:flex;
    flex-wrap: wrap;
    margin: auto;
    overflow: auto;
}

.image-single{
    text-align: center;
    margin: 15px 5px 0px 5px;
    width: 140px;
    height: 120px;
    overflow: hidden;
    cursor: pointer;
}

.image-single input{
    width: 50px;
    position: absolute;
}

.image-single p{
    /* position: absolute; */
    bottom: 6px;
    width: 100px;
    height: 45px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.8);
    word-wrap: break-word;
    display: none;
}


.image-item{
    position: relative;
    height: 110px;
    width: 130px;
    top: 5px;
}

.image-item:hover{
    top:2px;
    height: 115px;
    width: 135px;
}


    
</style>