import { ref, watchEffect } from "vue"
import { projectFirestore } from "../firebase/config"

const getCollection = (collection, query) => {

    const error = ref(null)
    const documents = ref(null)

    let collectionRef = projectFirestore.collection(collection)
        .orderBy('createdAt')

    if (query) {
        //... = spreading 
        collectionRef = collectionRef.where(...query)
    }

    const unsub = collectionRef.onSnapshot((snap)=>{
        let result = []
        snap.docs.forEach(doc => {
            doc.data().createdAt && result.push({...doc.data(), id:doc.id})
        });

        documents.value = result
        error.value = null
    }, (err) => {
        console.log(err.message)
        error.value = "Couldn't fetch message "
    })

    watchEffect((onInvalidate)=>{
        onInvalidate(() => unsub() )
    })

    return {error, documents}

}

export default getCollection