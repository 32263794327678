<template>
    <div class='content'>
            <AddPicture  :path="path"/>
        <br>
        <hr>
        <br>
        <div class='landing-image' v-if="landing">
            <div class='hero-image'>
                <h1>
                    Hero Image
                    <button v-if="!isSaved" @click="handleSubmit" class="submitBtn">USE THIS IMAGE</button>
                </h1>
                <span class='savedMessage' v-if="imageSavedMessage" >{{ imageSavedMessage }}</span>
                <div class='hero-image-container' 
                :style="`background:url(${heroImage}); 
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center center;`">
                    <!-- <img :src="heroImage" alt=""> -->
                </div>
            </div>
            <div>
                <h1>Image Gallery</h1>
                <div class='image-gallery'>
                    <div class="image-container" v-for="image in images" :key="image.name" @click="handleClick(image.url)">
                        <img :src="image.url" alt="">
                        <div class='overlay-img'></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="notif" :class="{ 'notif-success' : notifSuccess }">{{ notifSuccess }}</div>
    </div>
</template>
<script>
import AddPicture from '@/components/AddPicture.vue'
import PictureListView from '@/components/PictureListView.vue'
import getStorage from '@/composables/getStorage'
import getDocument from '@/composables/getDocument'
import useDocument from '@/composables/useDocument'
import { ref } from '@vue/reactivity'
import { onUpdated } from '@vue/runtime-core'

export default {
    components:{AddPicture, PictureListView},
    setup(props) {
        const id = "W9las9dNCjotMEpxakZX"
        const path = ref('landing')
        const heroImage = ref('')
        const {getImageList} = getStorage()
        const isPending = ref(false)
        const images = ref([])
        const isSaved = ref(true)
        const imageSavedMessage = ref(null)
        const { document:landing } = getDocument('landing-page', id)
        const isInitial = ref(true)
        const notifSuccess = ref(null)

        const { updateDoc } = useDocument('landing-page', id)

        const loadImage = async () => {
            isPending.value = true
            const res = await getImageList(`landing/`)
            res.forEach( async(item)=>{
                const res2 = await item.getDownloadURL()
                images.value.push({
                    url : res2,
                    name : item.name
                })
            })
            isPending.value = false
        }

        loadImage()

        onUpdated(()=>{
            if(isInitial.value){
                heroImage.value = landing.value.heroImage
                isInitial.value = false
                if(heroImage.value == ""){
                    heroImage.value = "https://dummyimage.com/600x400/c4c4c4/8f8f8f.png&text=Insert+image+here"
                }else{
                    imageSavedMessage.value = "Image Used"
                }
            }
        })

        const handleClick = (url) => {
            heroImage.value = url
            isSaved.value = false
            
            if(landing.value.heroImage != '' && landing.value.heroImage == heroImage.value){
                imageSavedMessage.value = "Image Used"
                isSaved.value = true
            }else{
                imageSavedMessage.value = null
                isSaved.value = false
            }
        }

        const handleSubmit = async() => {
            await updateDoc({
                heroImage:heroImage.value
            })
            imageSavedMessage.value="Image Used"
            isSaved.value = true
            notifSuccess.value = "Data Updated"
            setTimeout(() => {
                notifSuccess.value = null
            },3000)

        }

        return { path, heroImage, images, handleClick, isSaved, landing, imageSavedMessage, handleSubmit, notifSuccess }
    }

    
}
</script>
<style scoped>
    .submitBtn{
        /* float: right; */
        position: relative;
        top: -5px;
        background-color:limegreen;
        color: #ddd;
        font-size: 10px;
        padding:8px;
    }
    .submitBtn:hover{
        color: white;
    }
    h1{
        margin-bottom: 20px;
    }
    .savedMessage{
        position:absolute;
        padding: 10px;
        background-color: limegreen;
        color: white;
    }
    .content{
        max-width: 1200px;
        margin: 20px auto;
        background: white;
        padding:30px;
        border-radius:5px;
    }
    .landing-image{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 20px;
    }
    .hero-image-container{
        height: 360px;
        overflow: hidden;
    }
    .image-gallery{
        height: 318px;
        overflow-y: auto;
        border: 1px solid #ddd;
        padding: 20px;
        display: flex;
    }

    .image-container{
        position: relative;
        height: 150px;
        width: 150px;
        display: inline-block;
        cursor: pointer;
        margin-right: 20px;
        overflow: hidden;
    }

    .image-gallery img{
        max-height: 100%;
        cursor: pointer;
        position:absolute;
        bottom: 0px;
    }

    .image-container:hover{
        box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.5);
        transform: scale(1.02);
        transition: all ease 0.2s;
    }

    /* .overlay-img{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 0px;
        height: 0px;
        background-color: rgba(0, 0, 0, 0.2);
    }

    .image-container:hover .overlay-img{
        display: block;
        width: 100%;
        height: 100%;
    } */

</style>